// Dashboard
import Home from "./views/home";
import Project from "./views/project";

// List of routes
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Home
  },
  {
    path: "/projects",
    exact: true,
    name: "Projects",
    component: Project
  }
];

export default routes;
