import React from "react";
import {Button} from "reactstrap";

class Projects extends React.Component {
    render() {
        return (
            <>
                <div className="container">
                    <h1 className="text-danger">Projects</h1>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="mt-4 mb-5">
                                <h3 className="text-info">Node JS project</h3>
                                <span>Simple server, simple API, routes, templates, modules.</span>
                            </div>
                        </div>
                        <div className="col-md-4 mt-auto mb-auto">
                            <Button outline color="info"><i className="fa fa-github"></i> Source</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="mt-4 mb-5">
                                <h3 className="text-info">Todo List</h3>
                                <div>
                                    <span>React-Redux & Firebase</span>
                                </div>
                                <div className="mt-3">
                                    <span>Created post will be updated live along with time stamp, and also notification is displayed to all the users</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-auto mb-auto">
                            <Button outline color="info"><i className="fa fa-github"></i> Source</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="mt-4 mb-5">
                                <h3 className="text-info">Simple Countdown</h3>
                                <span>Simple counter clock with deadline</span>
                            </div>
                        </div>
                        <div className="col-md-4 mt-auto mb-auto">
                            <Button outline color="info"><i className="fa fa-github"></i> Source</Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Projects;
