import React from "react";
import Avatar from "../../components/core/Avatar";
import Typewriter from 'typewriter-effect';

//profile picture
import ProfilePicture from "../../assets/img/profilePicture.jpeg";
import Scale from "../../components/Scale";
import SocialMedia from "../../components/core/SocialMedia";

class Home extends React.Component {

    render() {
        const TypewriterString = ['Fullstack developer', 'Node + React'];
        const Skills = [
            {
                name: "HTML",
                value: 5
            }
            ,{
                name: "CSS",
                value: 4
            }
            ,{
                name: "React",
                value: 4
            }
            ,{
                name: "Node",
                value: 3
            }
            ,{
                name: "JavaScript",
                value: 4
            }
        ];
        return (
            <>
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3 col-lg-2">
                            <Avatar
                                firstName="Gowdham"
                                lastName="Rajendran"
                                size="customSize"
                                imageSize={"150"}
                                url={ProfilePicture}
                            />
                        </div>
                        <div className="col-md-9 mt-auto mb-auto">
                            <h3>Hi, there! I am
                                <span className="text-danger"> Gowdham!</span>
                            </h3>
                            <Typewriter
                                options={{
                                    strings: TypewriterString,
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <div className="">
                                <h3 className="mb-5">WELCOME ALIENS,</h3>
                                <span>
                                    <ul className="pl-0">I am a <span className="text-danger">fullstack developer</span> and a hardware enthusiastic
                                    currently learning <span className="text-info">web development.</span></ul>
                                    <ul className="pl-0">I currently working on projects based on <span className="text-info">React</span> + <span className="text-danger">Node</span></ul>
                                    <ul className="pl-0">Apart from development, i love <span className="text-info">photography</span>, on
                                     <a className="text-decoration-none text-danger" href="https://www.instagram.com/gowdham_photography/"> Instagram</a> I share my view as photographs</ul>
                                    <ul className="pl-0">I was born in Coimbatore and currently in Bangalore,
                                    working as a developer.</ul>
                                    <ul className="pl-0">I've started my career as fullstack developer in <span className="text-info">Thidiff Technologies</span> from <span className="text-danger">Sep 1, 2019</span> to till date</ul>
                                    <ul className="pl-0">I've done few freelancing projects based on <span className="text-danger">PHP, HTML, CSS, JS</span> and <span className="text-info">IOT</span> projects</ul>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <h4>Skills</h4>
                            <Scale data={Skills} />
                        </div>
                    </div>
                    <div>
                        <SocialMedia />
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
