import React from 'react';

const SocialMedia = (props) => {

    return (
        <div className="row mb-5">
            <div className="col">
                <h3 className="text-danger">Connect with me</h3>
                <div className="row mt-3">
                    <div className="col-md-3 mx-auto">
                        <h6 className="text-center"><a className="text-decoration-none text-info"
                               target="_blank" href="https://www.instagram.com/gowdham_photography/">INSTAGRAM</a></h6>
                    </div>
                    <div className="col-md-3 mx-auto">
                        <h6 className="text-center"><a className="text-decoration-none text-info"
                               target="_blank" href="https://twitter.com/prgowdham">TWITTER</a></h6>
                    </div>
                    <div className="col-md-3 mx-auto">
                        <h6 className="text-center"><a className="text-decoration-none text-info"
                               target="_blank" href="https://github.com/Gowdhampr">GITHUB</a></h6>
                    </div>
                    <div className="col-md-3 mx-auto">
                        <h6 className="text-center"><a className="text-decoration-none text-info"
                               target="_blank" href="https://linkedin.com/in/gowdhampr">LINKEDIN</a></h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialMedia;