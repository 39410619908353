import React from 'react';
import { Progress } from 'reactstrap';

const Scale = (props) => {

    const { data } = props;
    return (
        <div className="row">
            <div className="col-md-8">
                <div className="mt-4 mb-5">
                    {data.map(skill => (
                        <div>
                            <div className="text-left">{skill.name}</div>
                            <Progress bar color="info" value={skill.value} max="5">{skill.value}</Progress>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Scale;