import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import foxLogo from "../assets/img/foxLogo.png";
import {Link} from "react-router-dom";

const NavBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="pt-3">
            <Navbar color="faded" dark expand="md">
                <NavbarBrand href="/">
                    <img src={foxLogo} alt="Gowdham-Dev" className="jss1557" style={{ width: "50px" }} />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink className="font-weight-bold">
                                <Link to="/" className="font-weight-bold nav-link">HOME</Link>                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="font-weight-bold">
                                <Link to="/projects" className="font-weight-bold nav-link">PROJECTS</Link>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default NavBar;