import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";

//containers
import DefaultLayout from "./container/DefaultLayout/DefaultLayout";

//View
import Home from "./views/home"
import Project from "./views/project";

function App() {
  return (
      <div>
          <Router>
              <Switch>
                  <DefaultLayout
                      exact
                      name="Home"
                      path="/"
                      component={Home}
                  />
                  <DefaultLayout
                      exact
                      name="Project"
                      path="/projects"
                      component={Project}
                  />
              </Switch>
          </Router>
      </div>
  );
}

export default App;
