import React, { Component, Suspense } from "react";
import NavBar from "../../components/NavBar";
import {
    Switch,
    Route
} from "react-router-dom";

// routes config
import routes from "../../routes";

const DefaultFooter = React.lazy(() => import("./DefaultFooter"));

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight
        }
        this._updateDimensions = this._updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this._updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._updateDimensions);
    }

    // Update the screen height
    _updateDimensions() {
        this.setState({
            height: window.innerHeight
        });
    }

    loading = () => (
        <div className="animated fadeIn pt-1 text-center">Loading...</div>
    );

    render() {

        const { height } = this.state;
        const pathName = window.location.href;
        let showNav = "";

        return (
            <div className="app">
                <div className="app-body" style={{ minHeight: height }}>
                    <div className="container">
                        <NavBar/>
                        <div className="row">
                            <div className="col col-lg-12 pr-md-0">
                                <div className="site-maincontent">
                                    <Suspense>
                                        <Switch>
                                            {routes.map((route, idx) => {
                                                return route.component ? (
                                                    <Route
                                                        key={idx}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        name={route.name}
                                                        render={props => <route.component {...props} />}
                                                    />
                                                ) : "";
                                            })}
                                        </Switch>
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className={`footer ${showNav}`}>
                        <div className="container">
                            <Suspense fallback={this.loading()}>
                                <DefaultFooter />
                            </Suspense>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default DefaultLayout;
